import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

const BreadcrumbsListItem = ({ item: { link, name }, i, itemArray, light }) => (
  <li
    className={classNames('breadcrumbs-item-wrapper', {
      'breadcrumbs-item-wrapper--light': light,
    })}
    key={link}
  >
    {i === itemArray.length - 1 ? (
      <span className="breadcrumbs-item--current-element">{name}</span>
    ) : (
      <>
        <Link className="breadcrumbs-item-link" to={link === '/' ? link : `${link}/`}>
          {name}
        </Link>
        <div className="chevron-arrow-right" />
      </>
    )}
  </li>
);

export default BreadcrumbsListItem;
